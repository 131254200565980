<template>
  <CoreDialog
      :title="title"
      :loading="isLoading"
      :dialog-props="dialogProps"
      mode-switcher-enabled>
    <template slot="content">
      <CaneToadProductManagementForm
          :dialog-props="dialogProps"
          :product-data="productData" />
    </template>
  </CoreDialog>
</template>

<script>
  import CaneToadProductManagementForm from "../../forms/CaneToadProductManagementForm/CaneToadProductManagementForm"
  import { CaneToadProduct } from "@/Modules/Models"
  import startCase from "lodash/startCase"

  export default {
    name: "CaneToadProductManagementDialog",
    components: {
      CaneToadProductManagementForm
    },
    props: {
      id: { type: [String, Number], default: null },
      closeDialog: { type: Function, required: true },
      dialogProps: { type: Object, required: true },
    },
    data() {
      return {
        isLoading: true
      }
    },
    computed: {
      productData() {
        return CaneToadProduct.find(this.id)
      },
      mode() {
        return this.dialogProps.mode
      },
      title() {
        if (this.productData) {
          if (this.mode === 'duplicate') {
            return `Duplicate Product ${this.productData.Name} ${this.productData.SKU} (ID: ${this.id})`
          }
          return `${startCase(this.mode)}ing ${this.productData.Name} ${this.productData.SKU}  (ID: ${this.id})`
        }
        return "Create New Product"
      }
    },
    mounted() {
      if (this.id) {
        this.fetchProduct()
      } else {
        this.isLoading = false
      }
    },
    methods: {
      async fetchProduct() {
        this.isLoading = true

        await CaneToadProduct.api().fetchById(this.id)

        this.isLoading = false
      }
    }
  }
</script>