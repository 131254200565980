<template>
  <div class="enhanced-entity-select">
    <!-- Dropdown trigger -->
    <div class="select-trigger">
      <div class="selected-items">
        <template v-if="selectedItems.length">
          <div
              v-for="item in selectedItems"
              :key="item.value"
              class="selected-item">
            <img
                v-if="item.imageUrl"
                :src="item.imageUrl"
                :alt="item.label"
                class="item-image-small">
            <span>{{ item.label }}</span>
            <i
                class="el-icon-close"
                @click.stop="removeItem(item.value)" />
          </div>
        </template>
        <span
            v-else
            class="placeholder">{{ placeholder || 'Select items' }}</span>
      </div>
    </div>

    <!-- Dropdown panel -->
    <div class="dropdown-panel">
      <!-- Category selector (if enabled) -->
      <div
          v-if="showCategorySelector"
          class="category-container">
        <el-select
            v-model="selectedCategory"
            :placeholder="categoryPlaceholder || 'All Categories'"
            clearable
            @change="onCategoryChange">
          <el-option
              v-for="category in categories"
              :key="category.value"
              :label="category.label"
              :value="category.value" />
        </el-select>
      </div>

      <!-- Search input -->
      <div
          v-if="filterable"
          class="search-container">
        <el-input
            v-model="searchQuery"
            placeholder="Search"
            prefix-icon="el-icon-search"
            clearable />
      </div>

      <!-- Table header -->
      <div class="table-header">
        <div
            v-if="showImages"
            class="column image-column">
          Image
        </div>
        <div class="column label-column">
          {{ labelColumnTitle || 'Name' }}
        </div>
        <div
            v-if="showDetails"
            class="column details-column">
          {{ detailsColumnTitle || 'Details' }}
        </div>
        <div class="column actions-column">
          Actions
        </div>
      </div>

      <!-- Loading indicator -->
      <div
          v-if="loading"
          class="loading-container">
        <i class="el-icon-loading" />
        <span>Loading...</span>
      </div>

      <!-- Table body -->
      <div
          v-else
          class="table-body">
        <template v-if="filteredOptions.length">
          <div
              v-for="item in filteredOptions"
              :key="item.value"
              class="table-row"
              :class="{ 'is-selected': isSelected(item.value) }"
              @click="toggleSelection(item)">
            <div
                v-if="showImages"
                class="column image-column">
              <img
                  v-if="item.imageUrl"
                  :src="item.imageUrl"
                  :alt="item.label"
                  class="item-image">
              <div
                  v-else
                  class="image-placeholder" />
            </div>
            <div class="column label-column">
              {{ item.label }}
              <div
                  v-if="item.extra"
                  class="item-extra">
                {{ item.extra }}
              </div>
            </div>
            <div
                v-if="showDetails"
                class="column details-column">
              {{ getItemDetails(item) }}
            </div>
            <div class="column actions-column">
              <el-button
                  v-if="!isSelected(item.value)"
                  size="mini"
                  type="primary"
                  icon="el-icon-plus"
                  @click.stop="addItem(item.value)">
                Add
              </el-button>
              <el-button
                  v-else
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  @click.stop="removeItem(item.value)">
                Remove
              </el-button>

              <el-tooltip
                  v-if="item.entity && hasDialog(item.entity)"
                  :content="`Open ${item.entity.valueList.label} Dialog`"
                  placement="top">
                <el-button
                    size="mini"
                    icon="el-icon-top-right"
                    class="open-dialog"
                    @click.stop="(e) => openEntityDialog(item.entity, e)" />
              </el-tooltip>
            </div>
          </div>
        </template>
        <div
            v-else
            class="no-data">
          <template v-if="!searchQuery && !selectedCategory">
            Please enter a search term or select a category
          </template>
          <template v-else>
            No matching items found
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Models from "@/Modules/Models";
import get from "lodash/get";
import merge from "lodash/merge";

export default {
  name: "EnhancedEntitySelect",
  props: {
    value: {
      type: [Array, String, Number],
      default: () => []
    },
    placeholder: {
      type: String,
      default: ""
    },
    entity: {
      type: String,
      required: true
    },
    fieldPath: {
      type: String,
      default: ""
    },
    validation: {
      type: Array,
      default: () => []
    },
    addOptions: {
      type: Array,
      default: () => []
    },
    groupBy: {
      type: Object,
      default: null
    },
    preFilter: {
      type: Object,
      default: null
    },
    sortBy: {
      type: Object,
      default: null
    },
    queryBy: {
      type: Object,
      default: null
    },
    filterable: {
      type: Boolean,
      default: true
    },
    showImages: {
      type: Boolean,
      default: true
    },
    showDetails: {
      type: Boolean,
      default: true
    },
    labelColumnTitle: {
      type: String,
      default: ""
    },
    detailsColumnTitle: {
      type: String,
      default: ""
    },
    detailsFields: {
      type: Array,
      default: () => []
    },
    imageField: {
      type: String,
      default: "imageUrl"
    },
    imageBaseUrl: {
      type: String,
      default: ""
    },
    multiple: {
      type: Boolean,
      default: true
    },
    searchFields: {
      type: Array,
      default: () => ['label', 'value', 'extra', 'description']
    },
    // Category selector props
    showCategorySelector: {
      type: Boolean,
      default: false
    },
    categoryEntity: {
      type: String,
      default: ""
    },
    categoryValueField: {
      type: String,
      default: "id"
    },
    categoryLabelField: {
      type: String,
      default: "title"
    },
    categoryPlaceholder: {
      type: String,
      default: ""
    },
    categoryFilterField: {
      type: String,
      default: "category"
    }
  },
  data() {
    return {
      loading: true,
      flatOptions: [],
      searchQuery: "",
      openedCreateDialog: false,
      selectedCategory: '',
      categories: [],
      allItems: [] // Store all items regardless of filters
    };
  },
  computed: {
    modelEntity() {
      return Models[this.entity];
    },
    entityName() {
      return this.modelEntity.entity;
    },
    newEntity() {
      return new this.modelEntity();
    },
    dialogStack() {
      return this.$store.state.core.dialogStack.stack;
    },
    normalizedValue() {
      // Ensure value is always an array internally
      if (this.multiple) {
        return Array.isArray(this.value) ? this.value : [this.value].filter(Boolean);
      }
      return this.value ? [this.value] : [];
    },
    options() {
      let result = this.flatOptions;

      if (this.preFilter) {
        result = this.preFilterOptions(result);
      }

      if (this.sortBy) {
        result = this.sortByOptions(result);
      }

      // Apply category filter if a category is selected
      if (this.selectedCategory && this.showCategorySelector) {
        result = this.filterByCategory(result);
      }

      return result;
    },
    filteredOptions() {
      // If no search query and no category selected, return empty array or all items based on config
      if (!this.searchQuery && !this.selectedCategory) {
        return [];
      }

      // If category is selected but no search query, return all items in that category
      if (!this.searchQuery && this.selectedCategory) {
        return this.options;
      }

      // If there's a search query, filter by it
      if (this.searchQuery) {
        // Split the search query into terms and remove empty strings
        const searchTerms = this.searchQuery.toLowerCase().split(/\s+/).filter(Boolean);

        // If there are no search terms after filtering, return empty array
        if (searchTerms.length === 0) {
          return [];
        }

        return this.options.filter(item => {
          // Check if all search terms match across any of the searchable fields
          return searchTerms.every(term => this.itemMatchesSearchTerm(item, term));
        });
      }

      return [];
    },
    selectedItems() {
      return this.normalizedValue.map(value => {
        // First try to find the item in the current filtered options
        let item = this.options.find(option => option.value === value);

        // If not found in filtered options, look in the complete set of items
        if (!item) {
          item = this.allItems.find(option => option.value === value);
        }

        // If still not found, create a fallback item
        if (!item) {
          // Try to fetch the item from the entity model directly
          try {
            const record = this.modelEntity.find(value);
            if (record) {
              item = {
                ...record.valueList,
                entity: record,
                value: value,
                imageUrl: this.getImageUrl(record)
              };

              // Add to allItems for future reference
              this.allItems.push(item);
            }
          } catch (e) {
            console.warn(`Could not find entity with value ${value}`, e);
          }
        }

        // Fallback to simple value/label if all else fails
        return item || { value, label: `Item ${value}` };
      });
    }
  },
  watch: {
    dialogStack: {
      handler() {
        const items = this.dialogStack.filter(
            dialog => dialog.type.indexOf(this.entityName) !== -1
        );
        if (items.length > 0) {
          this.openedCreateDialog = true;
        } else {
          if (this.openedCreateDialog) {
            this.openedCreateDialog = false;
          }
        }
      },
      deep: true
    },
    openedCreateDialog: {
      handler(val) {
        if (!val) {
          this.getEntityItems();
        }
      }
    },
  },
  async beforeMount() {
    await this.getEntityItems();

    // Load categories if the category selector is enabled
    if (this.showCategorySelector && this.categoryEntity) {
      await this.loadCategories();
    }
  },
  methods: {
    async getEntityItems() {
      // Check how many items are currently in the db and only run the fetch if there are more
      const dbEntitiesResponse = await this.modelEntity.api().count();

      // Handle different API response formats
      let dBEntitiesCount = 0;
      if (dbEntitiesResponse.response.data.data.meta?.total) {
        dBEntitiesCount = dbEntitiesResponse.response.data.data.meta.total;
      } else {
        dBEntitiesCount = dbEntitiesResponse.response.data.data.data.count;
      }

      if (parseInt(dBEntitiesCount) > this.modelEntity.all().length) {
        this.loading = true;

        // Fetch the items using the total of the db
        await this.modelEntity
            .api()
            .fetchAll(
                merge(
                    {
                      params: {
                        per_page: parseInt(dBEntitiesCount),
                        page: 1
                      }
                    },
                    {}
                )
            );
      }

      // Process all items and store them
      const processedItems = this.modelEntity.all().map(record => ({
        ...record.valueList,
        entity: record,
        imageUrl: this.getImageUrl(record)
      }));

      // Keep a copy of all items for reference when selected items are filtered out
      this.allItems = [...processedItems, ...this.addOptions];

      // Set the flatOptions for filtering
      this.flatOptions = [...processedItems, ...this.addOptions];

      this.loading = false;
    },
    async loadCategories() {
      if (!this.categoryEntity) return;

      const categoryModel = Models[this.categoryEntity];

      if (!categoryModel) {
        console.error(`Category entity model '${this.categoryEntity}' not found`);
        return;
      }

      // Check if we need to fetch categories
      const dbCategoriesResponse = await categoryModel.api().count();

      // Handle different API response formats for the count
      let dbCategoriesCount = 0;
      if (dbCategoriesResponse.response.data.data.meta?.total) {
        dbCategoriesCount = dbCategoriesResponse.response.data.data.meta.total;
      } else {
        dbCategoriesCount = dbCategoriesResponse.response.data.data.data.count;
      }

      if (parseInt(dbCategoriesCount) > categoryModel.all().length) {
        // Fetch all categories
        await categoryModel.api().fetchAll(
            merge(
                {
                  params: {
                    per_page: parseInt(dbCategoriesCount),
                    page: 1
                  }
                },
                {}
            )
        );
      }

      // Map categories to options
      this.categories = categoryModel.all().map(category => ({
        value: get(category, this.categoryValueField),
        label: get(category, this.categoryLabelField),
        entity: category
      }));

      // filter out categories with id 0
      this.categories = this.categories.filter(category => category.value !== 0);

      // Sort categories by label
      this.categories.sort((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      });
    },
    filterByCategory(items) {
      if (!this.selectedCategory) return items;

      return items.filter(item => {
        // Get category ID or array of category IDs from the item
        const itemCategory = get(item.entity, this.categoryFilterField);

        // Handle case where item has an array of categories
        if (Array.isArray(itemCategory)) {
          return itemCategory.includes(this.selectedCategory);
        }

        // Handle case where item has a single category
        return itemCategory === this.selectedCategory;
      });
    },
    onCategoryChange() {
      // Reset search when category changes
      this.searchQuery = '';
    },
    isSelected(value) {
      return this.normalizedValue.includes(value);
    },
    toggleSelection(item) {
      if (this.isSelected(item.value)) {
        this.removeItem(item.value);
      } else {
        this.addItem(item.value);
      }
    },
    addItem(value) {
      if (this.multiple) {
        if (!this.normalizedValue.includes(value)) {
          const newValue = [...this.normalizedValue, value];
          this.$emit("input", newValue);
          this.$emit("change", newValue);
        }
      } else {
        this.$emit("input", value);
        this.$emit("change", value);
      }
    },
    removeItem(value) {
      if (this.multiple) {
        const newValue = this.normalizedValue.filter(v => v !== value);
        this.$emit("input", newValue);
        this.$emit("change", newValue);
      } else {
        this.$emit("input", "");
        this.$emit("change", "");
      }
    },
    hasDialog(entity) {
      return get(entity, "Actions.openDialog");
    },
    openCreateDialog() {
      this.newEntity.Actions.openDialog();
    },
    openEntityDialog(entity, e) {
      if (e) e.stopPropagation();
      entity.Actions.openDialog();
    },
    getImageUrl(record) {
      // First try to get an image from the ImageList array if it exists
      const imageList = get(record, 'ImageList');
      if (Array.isArray(imageList) && imageList.length > 0) {
        // Get the first image from the array
        const baseUrl = this.imageBaseUrl || '';  // You can set this via prop if needed
        return `${baseUrl}${imageList[0]}`;
      }

      // Fall back to the specified image field
      return get(record, this.imageField);
    },
    itemMatchesSearchTerm(item, searchTerm) {
      // Check the direct properties of the item
      if (this.checkDirectProperties(item, searchTerm)) {
        return true;
      }

      // If item has an entity property, search in additional entity fields
      if (item.entity) {
        return this.checkEntityProperties(item.entity, searchTerm);
      }

      return false;
    },
    checkDirectProperties(item, searchTerm) {
      // Check standard item properties (label, value, extra)
      if (String(item.label || '').toLowerCase().includes(searchTerm)) {
        return true;
      }
      if (String(item.value || '').toLowerCase().includes(searchTerm)) {
        return true;
      }
      if (item.extra && String(item.extra).toLowerCase().includes(searchTerm)) {
        return true;
      }

      return false;
    },
    checkEntityProperties(entity, searchTerm) {
      // Check in details fields if provided
      if (this.detailsFields.length > 0) {
        for (const field of this.detailsFields) {
          const value = get(entity, field.path, '');
          if (String(value).toLowerCase().includes(searchTerm)) {
            return true;
          }
        }
      }

      // Check in additional search fields from searchFields prop
      for (const field of this.searchFields) {
        const value = get(entity, field, '');
        if (String(value).toLowerCase().includes(searchTerm)) {
          return true;
        }
      }

      // Check in description if present
      const description = get(entity, 'description', '');
      if (description && String(description).toLowerCase().includes(searchTerm)) {
        return true;
      }

      return false;
    },
    getItemDetails(item) {
      if (!this.detailsFields.length) {
        return item.extra || item.value;
      }

      return this.detailsFields.map(field => {
        const value = get(item.entity, field.path, "");
        return value ? `${field.label || ''}: ${value}` : '';
      }).filter(Boolean).join(', ');
    },
    preFilterOptions(items) {
      if (this.preFilter?.id) {
        return items.filter(item => {
          return item.entity[this.preFilter.filterBy] === this.preFilter.id;
        });
      }
      if (!this.preFilter?.id && this.preFilter?.required) {
        return [];
      }
      return this.flatOptions;
    },
    sortByOptions(items) {
      if (!this.sortBy) return items;

      const { field, order } = this.sortBy;
      return items.sort((a, b) => {
        const aValue = get(a.entity, field);
        const bValue = get(b.entity, field);

        if (aValue < bValue) {
          return order === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return order === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.enhanced-entity-select {
  position: relative;
  width: 100%;
  min-height: 30vh;
  line-height: 1.3;
}

.select-trigger {
  display: flex;
  align-items: center;
  min-height: 40px;
  padding: 5px 10px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    border-color: #c0c4cc;
  }

  &:focus {
    border-color: #409eff;
  }
}

.selected-items {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.selected-item {
  display: flex;
  align-items: center;
  padding: 2px 8px;
  background-color: #f0f2f5;
  border-radius: 4px;
  margin-right: 4px;
  width: 100%;
  font-size: 1rem;

  .item-image-small {
    width: 3rem;
    height: 3rem;
    object-fit: cover;
    border-radius: 2px;
    margin-right: 5px;
  }

  .el-icon-close {
    margin-left: auto;
    color: #909399;
    cursor: pointer;

    &:hover {
      color: #f56c6c;
    }
  }
}

.placeholder {
  color: #a8abb2;
}

.dropdown-panel {
  width: 100%;
  max-height: 400px;
  margin-top: 5px;
  background-color: #fff;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.category-container {
  padding: 10px;
  border-bottom: 1px solid #ebeef5;

  .el-select {
    width: 100%;
  }
}

.search-container {
  padding: 10px;
  border-bottom: 1px solid #ebeef5;
}

.table-header {
  display: flex;
  padding: 10px;
  background-color: #f5f7fa;
  border-bottom: 1px solid #ebeef5;
  font-weight: bold;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: #909399;

  .el-icon-loading {
    margin-right: 5px;
  }
}

.table-body {
  max-height: 300px;
  overflow-y: auto;
}

.table-row {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #ebeef5;
  transition: background-color 0.2s;

  &:hover {
    background-color: #f5f7fa;
  }

  &.is-selected {
    background-color: #ecf5ff;
  }
}

.column {
  padding: 0 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.image-column {
  width: 60px;
  flex-shrink: 0;
}

.item-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
}

.image-placeholder {
  width: 50px;
  height: 50px;
  background-color: #f0f2f5;
  border-radius: 4px;
}

.label-column {
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.item-extra {
  font-size: 0.8em;
  color: #909399;
  margin-top: 2px;
}

.details-column {
  flex: 1;
}

.actions-column {
  flex: 1;
  min-width: 140px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
}

.no-data {
  padding: 20px;
  text-align: center;
  color: #909399;
}

.create-entity-container {
  padding: 10px;
  border-top: 1px solid #ebeef5;
  text-align: center;
}

.create-entity-button {
  width: 100%;

  i {
    margin-right: 5px;
  }
}

.open-dialog {
  border: 1px solid inherit;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}
</style>