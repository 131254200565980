<template>
  <el-tooltip
      v-if="renderTooltip"
      v-bind="mergedTooltipProps">
    <slot name="content">
      <p
          slot="content"
          class="core-text"
          :data-test-id="dataTestId"
          v-html="mergedTooltipProps.content" />
    </slot>
    <component
        :is="tag"
        ref="text"
        class="core-text"
        :class="classNames"
        :data-test-id="dataTestId"
        v-on="$listeners">
      {{ currencySymbol }}<slot /> {{ currency }}
    </component>
  </el-tooltip>

  <component
      :is="tag"
      v-else
      ref="text"
      class="core-text"
      :class="classNames"
      :data-test-id="dataTestId"
      v-on="$listeners">
    {{ currencySymbol }}<slot /> {{ currency }}
  </component>
</template>

<script>
import get from "lodash/get"
import debounce from "lodash/debounce"

export default {
  name: "CoreDialogStack",
  props: {
    tag: { type: String, default: "p" },
    size: { type: String, default: null },
    label: { type: Boolean, default: false },
    bold: { type: Boolean, default: false },
    semibold: { type: Boolean, default: false },
    uppercase: { type: Boolean, default: false },
    capitalize: { type: Boolean, default: false },
    clickable: { type: Boolean, default: false },
    currency: { type: String, default: null },
    searchValue: { type: String, default: null },
    truncate: { type: Boolean, default: false },
    tooltipProps: { type: Object, default: null },
    link: { type: Boolean, default: false },
    hint: { type: Boolean, default: false },
    info: { type: Boolean, default: false },
    dataTestId: { type: String, default: null }
  },
  data() {
    return {
      tooltipContent: "",
      truncated: false,
      originalInnerHTML: null
    }
  },
  computed: {
    text() {
      return this.$slots.default[0].text
    },
    currencySymbol() {
      const { currency } = this

      return currency ? "$" : ""
    },
    renderTooltip() {
      const { truncated, truncate, tooltipProps } = this

      return truncate ? truncated : tooltipProps
    },
    classNames() {
      const { $props, size } = this

      return { ...$props, [size]: size }
    },
    mergedTooltipProps() {
      const defaultTooltipProps = {
        popperClass: "info-tooltip",
        openDelay: 400,
        placement: "top",
        content: this.tooltipContent
      }

      if (this.tooltipProps) {
        return { ...defaultTooltipProps, ...this.tooltipProps }
      }

      return { ...defaultTooltipProps }
    }
  },
  watch: {
    searchValue: {
      immediate: true,
      handler() {
        if (this.searchValue) {
          this.$nextTick(() => {
            this.debouncedHighlightString()
          })
        } else {
          this.getTextRef()
        }
      }
    }
  },
  mounted() {
    this.debouncedHighlightString = debounce(this.highlightString, 200)

    this.$nextTick(() => {
      this.setTooltipContent()
      this.setIsTruncated()
    })
  },
  methods: {
    getTextRef() {
      const textRef = this.$refs.text
      const { originalInnerHTML } = this

      if (!textRef) return null

      if (!originalInnerHTML) {
        this.originalInnerHTML = textRef.innerHTML
      } else {
        textRef.innerHTML = this.originalInnerHTML
      }

      return textRef
    },
    highlightString() {
      this.$nextTick(() => {
        const { searchValue } = this
        const ref = this.getTextRef()
        const regex = new RegExp(`${searchValue}`, "i")

        if (!searchValue) {
          return
        }

        if (ref) {
          const matchedValue = ref.innerHTML.match(regex)

          if (matchedValue) {
            const [firstPart, ...rest] = ref.innerHTML.split(regex)

            const addMarkAndMatchingValue = subStr => `<mark>${matchedValue[0]}</mark>${subStr}`

            const stringPartsWithHighlights = [firstPart, ...rest.map(addMarkAndMatchingValue)]

            const highlightedString = stringPartsWithHighlights.join("")

            ref.innerHTML = highlightedString
          }
        }
      })
    },
    setTooltipContent() {
      this.tooltipContent = get(this.$refs, "text.innerHTML", "")
    },
    setIsTruncated() {
      if (this.truncate) {
        this.truncated = this.$refs.text.offsetWidth < this.$refs.text.scrollWidth
      }
    }
  }
}
</script>


<style lang="scss" scoped>
label.core-text {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.78);
  font-size: 0.85em;
}

h3.core-text {
  font-weight: 500;
}

.core-text {
  margin: 0;
  display: inline-block;
  font-size: 1rem;

  &.sml {
    font-size: 0.75rem;
  }
  &.semibold {
    font-weight: 500;
  }
  &.bold {
    font-weight: 600;
  }
  &.uppercase {
    text-transform: uppercase;
  }
  &.capitalize {
    text-transform: capitalize;
  }
  &.label {
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.85em;
  }
  &.hint {
    color: rgba(0, 0, 0, 0.4);
  }
  &.info {
    color: rgba(0, 0, 0, 0.45);
    font-weight: 500;
    font-style: italic;
  }
  &.link {
    color: #409eff;
    text-decoration: underline;
    cursor: pointer;
  }
  &.clickable {
    cursor: pointer;
    transition: all 0.05s;
  }

  &.alert-warning {
    border: 1px solid #f7ba2a;
    border-radius: 4px;
    padding: 10px;
    background: #f7ba2a;
    color: black;
    word-break: break-all;
    white-space: pre-line;
  }
}
</style>
