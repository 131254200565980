import autobind from "auto-bind"

class BulkActions {
  constructor(entity) {
    this.entity = entity

    autobind(this)
  }

  async bulkRefreshPaymentStatus(records) {
    const { store } = this.entity

    const ids = records.map(({ orderID }) => orderID)

    const response = await this.entity.Api.bulkRefreshPaymentStatus(ids)

    let notificationsPayload

    if (response) {
      notificationsPayload = {
        component: "Toast",
        type: "success",
        title: "Thank you",
        message: `All selected orders have been refreshed`,
        visible: true,
        decay: 0
      }
    } else {
      notificationsPayload = {
        component: "Toast",
        type: "error",
        title: "Error refreshing orders",
        message: `Sorry, some orders couldn't be refreshed.`,
        visible: true,
        decay: 3500
      }
    }
    store.dispatch("core/notifications/addToNotifications", notificationsPayload)
    // Reload orders that have been updated
    this.entity.Api.fetchByIds(ids)
  }

  async bulkRefreshPaymentStatusUnpaidOrders(records) {
    const { store } = this.entity

    // filter records that are marked as unpaid

    const unpaidOrders = records.filter(({ status }) => !status.includes('paid'))
    const unpaidOrderIds = unpaidOrders.map(({ orderID }) => orderID)

    const response = await this.entity.Api.bulkRefreshPaymentStatus(unpaidOrderIds)

    let notificationsPayload

    if (response) {
      notificationsPayload = {
        component: "Toast",
        type: "success",
        title: "Thank you",
        message: `All selected orders have been refreshed`,
        visible: true,
        decay: 0
      }
    } else {
      notificationsPayload = {
        component: "Toast",
        type: "error",
        title: "Error refreshing orders",
        message: `Sorry, some orders couldn't be refreshed.`,
        visible: true,
        decay: 3500
      }
    }
    store.dispatch("core/notifications/addToNotifications", notificationsPayload)
    // Reload orders that have been updated
    this.entity.Api.fetchByIds(unpaidOrderIds)
  }

  get list() {
    return [
      {
        visible: true,
        onClick: this.bulkRefreshPaymentStatusUnpaidOrders,
        label: "Refresh Unpaid Orders",
        icon: "el-icon-circle-check",
        buttonType: "success"
      },
      {
        visible: true,
        onClick: this.bulkRefreshPaymentStatus,
        label: "Refresh ALL Orders",
        icon: "el-icon-circle-check",
        buttonType: "success"
      },
    ]
  }
}

export default BulkActions
